/**
 *
 * @param email
 * @returns {*}
 */
export function sendResetLinkEmail(email) {
    return axios({
        url: `api/auth/send-reset-link-email`,
        method: 'post',
        data: {
            email: email
        }
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function resetPassword(data) {
    return axios({
        url: `/api/auth/password/reset`,
        method: 'post',
        data: data
    });
}
